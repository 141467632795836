import { Injectable } from '@angular/core';
import { Key } from '../enum/global.enum';

@Injectable()
export class HelperService {
  public isAuthenticated(): boolean {
    return !!(
      !!localStorage.getItem(Key.TOKEN) &&
      localStorage.getItem(Key.TOKEN)?.length
    );
  }
}
