import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { HelperService } from '../../global/services/helper.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard {
  constructor(
    private helperService: HelperService,
    private router: Router
  ) {}

  canActivate(
    routeSnapShot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.isAuthenticated();
  }

  private isAuthenticated(): boolean {
    if (this.helperService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
